/** @format */

import React from "react";
import { Link } from "gatsby";

import { StyledNav, StyledUl, StyledLi } from "./StyledNav";

const Nav = ({ menuOpen }) => {
    return (
        <StyledNav menuopen={menuOpen}>
            <StyledUl>
                <StyledLi>
                    <Link activeClassName="active-link" to="/">
                        Start
                    </Link>
                </StyledLi>
                <StyledLi>
                    <Link activeClassName="active-link" to="/o-nas">
                        O nas
                    </Link>
                </StyledLi>
                <StyledLi>
                    <Link activeClassName="active-link" to="/kontakt">
                        Kontakt
                    </Link>
                </StyledLi>
                <StyledLi>
                    <Link activeClassName="active-link" to="/galeria">
                        Galeria
                    </Link>
                </StyledLi>
                <StyledLi>
                    <Link activeClassName="active-link" to="/pokoje">
                        Pokoje
                    </Link>
                </StyledLi>
                <StyledLi>
                    <Link activeClassName="active-link" to="/cennik">
                        Cennik
                    </Link>
                </StyledLi>
                <StyledLi>
                    <Link activeClassName="active-link" to="/od-nas">
                        Od nas
                    </Link>
                </StyledLi>
                <StyledLi>
                    <Link activeClassName="active-link" to="/okolica">
                        Okolica
                    </Link>
                </StyledLi>
            </StyledUl>
        </StyledNav>
    );
};

export default Nav;

import React from "react";

import { Helmet } from "react-helmet";

const HelmetTemplate = ({ title, desc }) => {
  return (
    <Helmet htmlAttributes={{ lang: "pl" }}>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta name="robots" data-react-helmet="true" />
    </Helmet>
  );
};

export default HelmetTemplate;

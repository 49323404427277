/** @format */

import styled from "styled-components";

export const StyledHeader = styled.header`
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 60px;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1920px;
`;

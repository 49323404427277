/** @format */

import styled from "styled-components";

export const StyledHamburger = styled.button`
    position: relative;
    padding: 10px;
    display: inline-block;
    cursor: pointer;
    background-color: ${({ isscroll }) => (isscroll ? "#fff" : "transparent")};
    border: 0;
    border-radius: 10px;
    z-index: 101;
    transition: transform 300ms ease-in-out, background-color 250ms;
    transform: ${({ hamburger }) =>
        hamburger ? "translateX(240px)" : "translateX(0)"};

    @media only screen and (max-width: 400px) {
        transform: translateX(0);
    }
`;

export const StyledHamburgerBox = styled.span`
    width: 34px;
    height: 20px;
    display: inline-block;
    position: relative;
`;

export const StyledHamburgerInner = styled.span`
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 50ms ease-in-out;
    background-color: ${({ hamburger, hamburgercolor }) =>
        hamburger ? "transparent" : hamburgercolor ? "#fff" : "#000"};
    border-radius: 3px;
    &::after {
        content: "";
        left: 0;
        width: 100%;
        height: 3px;
        position: absolute;
        background-color: ${({ hamburger, hamburgercolor }) =>
            hamburger ? "#000" : hamburgercolor ? "#fff" : "#000"};
        top: 8px;
        transition: transform 100ms ease-in-out;
        border-radius: 3px;
        transform: ${({ hamburger }) =>
            hamburger
                ? "translateY(-8px) rotate(-135deg)"
                : "translateY(0) rotate(0)"};
    }
    &::before {
        content: "";
        left: 0;
        width: 100%;
        height: 3px;
        position: absolute;
        background-color: ${({ hamburger, hamburgercolor }) =>
            hamburger ? "#000" : hamburgercolor ? "#fff" : "#000"};
        top: -8px;
        transition: transform 100ms ease-in-out;
        border-radius: 3px;
        transform: ${({ hamburger }) =>
            hamburger
                ? "translateY(8px) rotate(135deg)"
                : "translateY(0) rotate(0)"};
    }
`;

/** @format */

import React, { useEffect, useState } from "react";

import {
    StyledHamburger,
    StyledHamburgerBox,
    StyledHamburgerInner,
} from "./StyledHamburger";

const HamburgerMenu = ({ menuOpen, isMenuOpen, hamburgerColor }) => {
    const [isScroll, setIsScroll] = useState(false);

    const handleIsScroll = () => {
        if (window.scrollY > 170) {
            setIsScroll(true);
        } else {
            setIsScroll(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleIsScroll);
    }, []);

    return (
        <StyledHamburger
            onClick={() => isMenuOpen(!menuOpen)}
            hamburger={menuOpen}
            isscroll={isScroll}
        >
            <StyledHamburgerBox>
                <StyledHamburgerInner
                    hamburger={menuOpen}
                    hamburgercolor={hamburgerColor}
                />
            </StyledHamburgerBox>
        </StyledHamburger>
    );
};

export default HamburgerMenu;

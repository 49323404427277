/** @format */

import React, { useState } from "react";
import { useLocation } from "@reach/router";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import Nav from "../Nav/Nav";

import { StyledHeader } from "./StyledHeader";

const Header = () => {
    const [menuOpen, isMenuOpen] = useState(0);
    const location = useLocation().pathname;
    return (
        <StyledHeader>
            <HamburgerMenu
                menuOpen={menuOpen}
                isMenuOpen={isMenuOpen}
                hamburgerColor={location === "/" ? true : false}
            />
            <Nav menuOpen={menuOpen} />
        </StyledHeader>
    );
};

export default Header;

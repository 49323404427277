/** @format */

import styled from "styled-components";

export const StyledNav = styled.nav`
    width: 300px;
    height: 100vh;
    min-height: 600px;
    background: #fff;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    transform: ${({ menuopen }) =>
        menuopen ? "translateX(0px)" : "translateX(-300px)"};
    opacity: ${({ menuopen }) => (menuopen ? "1" : "0")};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 400px) {
        width: 100%;
        transform: ${({ menuopen }) =>
            menuopen ? "translateX(0px)" : "translateX(-400px)"};
    }
`;

export const StyledUl = styled.ul`
    list-style-type: none;
`;

export const StyledLi = styled.li`
    padding: 21px 10px;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    > a {
        text-decoration: none;
        color: #000;
        transition: color 250ms;
        &:hover{
            color: #D95720;
        }
    }

`;

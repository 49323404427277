/** @format */

import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Header from "../Header/Header";
import GlobalStyle from "../../styles/GlobalStyle";

const StyledMain = styled.main`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    background-color: ${({ hasdeclaredbgcolor }) =>
        hasdeclaredbgcolor ? hasdeclaredbgcolor : "transparent"};
`;

const GlobalLayout = ({ children, bgColor }) => {
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: "pl",
                }}
            >
                <meta name="robots" data-react-helmet="true" />
                <meta name="robots" content="noindex" />
            </Helmet>
            <StyledMain hasdeclaredbgcolor={bgColor}>
                <Header />
                <GlobalStyle />
                {children}
            </StyledMain>
        </>
    );
};

export default GlobalLayout;

/** @format */

import { createGlobalStyle } from "styled-components";
import "./fonts.css";

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .active-link{
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 130%;
            height: 2px;
            border-radius: 5px;
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #FF4D00;
        }
    }
`;

export default GlobalStyle;
